<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>文章列表</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="资讯标题">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.title"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="文章类型">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.type"
							placeholder=""
						>
							<el-option label="文章" value="1"></el-option>
							<el-option label="快讯" value="2"></el-option>
							<el-option label="日历" value="3"></el-option>
							<el-option label="产业" value="4"></el-option>
							<el-option label="专栏" value="5"></el-option>
							<el-option label="活动" value="7"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<div class="block" style="margin-left: 20px">
							<span class="demonstration" style="margin-right: 20px"
								>请选择日期</span
							>
							<el-date-picker
								v-model="value2"
								type="daterange"
								align="right"
								unlink-panels
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								:picker-options="pickerOptions"
								@change="getDataList"
								style="margin-right: 20px"
							>
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
						<exportExcel :id="'exportTab'" :name="'资讯列表'"></exportExcel>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						:page-sizes="[100, 200, 500, 10000]"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import ExportExcel from "../../../components/ExportExcel.vue";
import { getWZListpvuv } from "@/api/data";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec, ExportExcel },
	name: "",
	data() {
		return {
			tableData: [],
			total: Number,
			categoryList: [],
			labelList: [],
			searchInfo: {
				title: "",
				category_id: "",
				label_id: "",
				type: "",
				user_type: "",
				page: 1,
				size: 100,
				startTime: "",
				endTime: "",
			},
			pickerOptions: {
				shortcuts: [
					{
						text: "本月",
						onClick(picker) {
							picker.$emit("pick", [new Date(), new Date()]);
						},
					},
					{
						text: "今年至今",
						onClick(picker) {
							const end = new Date();
							const start = new Date(new Date().getFullYear(), 0);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近六个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setMonth(start.getMonth() - 6);
							picker.$emit("pick", [start, end]);
						},
					},
				],
			},
			value1: [],
			value2: [],
		};
	},
	created() {
		let _obj = JSON.parse(sessionStorage.getItem("messageListKey_wm"));
		if (_obj) {
			Object.keys(this.searchInfo).forEach((key) => {
				this.searchInfo[key] = _obj[key] || this.searchInfo[key];
			});
		}
		// 初始化默认时间
		this.defaultDate();
		this.getDataList();
	},
	watch: {
		// 监听日期清理后数据为null进行处理否则会报错
		value2(newVal) {
			if (newVal == null) {
				this.value2 = null;
			}
		},
	},
	methods: {
		// 初始化默认时间
		defaultDate() {
			var date = new Date();
			var year = date.getFullYear().toString();
			var month =
				date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1).toString()
					: (date.getMonth() + 1).toString();
			// var da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
			var da = new Date(date.getFullYear(), month, 0).getDate();
			da < 10 ? "0" + da.toString() : da.toString();
			var beg = year + "-" + month + "-01";
			var end = year + "-" + month + "-" + da;
			this.value2 = [beg, end]; //将值设置给插件绑定的数据
		},
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		getDataList() {
			this.searchInfo.startTime = this.timeTrans(this.value2[0], 0);
			this.searchInfo.endTime = this.timeTrans(this.value2[1], 1);
			console.log(this.searchInfo.endTime);
			let _data = Object.assign(this.searchInfo, {});
			sessionStorage.setItem("messageListKey_wm", JSON.stringify(_data));
			const that = this;
			getWZListpvuv(this.searchInfo)
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						that.tableData = res.data.data;
						that.total = res.data.total;
					} else {
						that.tableData = [];
						that.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		timeTrans(time, type) {
			/*首先需要判断时间是否是空的  如果不判断的话就会出现错误，时间转换格式为空  空的时间肯定无法进行转换*/
			if (time == null || time == "") {
				return null;
			}
			let date = new Date(new Date(time).getTime() + 8 * 3600 * 1000);
			var year = date.getFullYear();
			/* 在日期格式中，月份是从0开始的，因此要加0
			 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
			 * */
			var month =
				date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1;
			var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
			var tmp = year + "-" + month + "-" + day + " 00:00:00";
			if (type == 0) {
				var tmp = year + "-" + month + "-" + day + " 00:00:00";
			} else {
				var tmp = year + "-" + month + "-" + day + " 23:59:59";
			}
			date = date.toDateString();
			return tmp;
		},
	},
};
</script>

<style scoped></style>

<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
		>
			<el-table-column prop="id" label="id" width="100px"> </el-table-column>
			<el-table-column prop="title" label="资讯标题" show-overflow-tooltip>
			</el-table-column>
			<el-table-column label="文章类型" width="80px">
				<template slot-scope="scope">
					<span v-if="scope.row.type == 1">文章</span>
					<span v-if="scope.row.type == 2">快讯</span>
					<span v-if="scope.row.type == 3">日历</span>
					<span v-if="scope.row.type == 4">产业</span>
					<span v-if="scope.row.type == 5">专栏</span>
					<span v-if="scope.row.type == 7">活动</span>
				</template>
			</el-table-column>
			<el-table-column prop="nickname" label="作者" min-width="20%">
			</el-table-column>
			<el-table-column prop="visits_num" label="阅读量" width="60px">
			</el-table-column>
			<el-table-column prop="release_time" label="发布时间" width="150px">
			</el-table-column>
			<el-table-column label="链接" width="160px">
				<template slot-scope="scope">
					<span
						class="pointer margin_left_10"
						@click="showPreView(scope.row)"
						>{{
							scope.row.type == 2
								? "https://metaera.com.hk/kuaixu_wz?id=" + scope.row.id
								: "https://metaera.com.hk/gw_detail?id=" + scope.row.id
						}}</span
					>
				</template>
			</el-table-column>

			<el-table-column label="详情" width="160px">
				<template slot-scope="scope">
					<span class="pointer margin_left_10" @click="detailView(scope.row)"
						>查看详情</span
					>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { disInformation, delInformation, informationHome } from "@/api/article";
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {
		showPreView(row) {
			let url = "https://metaera.com.hk/gw_detail?id=" + row.id;
			if (row.type == 2) {
				url = "https://metaera.com.hk/kuaixun_wz?id=" + row.id;
			}
			window.open(url, "预览");
		},

		detailView(row) {
			this.$router.push({
				path: "/webdata/singleList",
				query: { id: row.id },
			});
		},
	},
};
</script>

<style scoped></style>
